import type { Stripe } from 'stripe';
import type { CreateSubscriptionParams, Plan } from 'common/types';

import '../tbt-auth/tbt-auth';
import '@material/mwc-button';
import '@material/mwc-tab-bar';
import '@material/mwc-tab';
import '@power-elements/stripe-elements';
import { customElement, html, query, TemplateResult, property } from 'lit-element';

import { ifDefined } from 'lit-html/directives/if-defined';
import { styleMap } from 'lit-html/directives/style-map';

import {
  registerState,
  StateElement,
  updateState,
  when,
  getState,
  receivedProp,
} from '@pwrs/state';

import { Prices } from 'common/types';

import { StripeElements, StripePaymentRequest } from '@power-elements/stripe-elements';

import { safeCallable } from '../../firebase/functions';
import { capitalize } from '../../lib/capitalize';
import { loadScript } from '../../lib/load-script';
import { clock } from '../../lib/svg/clock.svg';

import shared from '../../shared.css';
import style from './tbt-subscribe.css';

interface SubscribeState {
  /** True when the create subscription request is in flight */
  inFlight: boolean;
  /** When true, show the loading scrim */
  loading: boolean;
  /** Stripe Payment Method */
  paymentMethod: Stripe.PaymentMethod['id'];
  /** True when Payment Request is unsupported */
  unsupported: boolean;
  /** Subscription returned from Stripe */
  subscription: Stripe.Subscription;
  /** Error returned from Stripe */
  error: Error|Stripe.StripeError;
  /** The selected plan */
  plan: Plan;
  /** Stripe Instance */
  stripe: StripeElements['stripe'];
}

type RequiresActionSubscription = Stripe.Subscription & {
  latest_invoice: Stripe.Invoice & {
    payment_intent: Stripe.PaymentIntent;
  };
}

type PaymentMethodEvent =
  CustomEvent<Stripe.PaymentMethod> & { target: StripeElements | StripePaymentRequest };

declare module '@pwrs/state/state' {
  export interface State {
    subscribe: SubscribeState;
  }
}

const stripeCreateSubscription =
  safeCallable<CreateSubscriptionParams, Stripe.Subscription>('stripeCreateSubscription');

const isString = (x: string|unknown): x is string => typeof x === 'string';

function shouldCreateSubscription(subscribeState: SubscribeState): boolean {
  const { error, paymentMethod, subscription, inFlight } = subscribeState;
  if (inFlight) return false;
  return !!(paymentMethod && !subscription && !error);
}

function isRequiresActionSubscription(
  subscription: Stripe.Subscription
): subscription is RequiresActionSubscription {
  /* eslint-disable @typescript-eslint/camelcase */
  const { status, latest_invoice } = subscription;
  if (status === 'active')
    return false;
  if (isString(latest_invoice))
    return false;
  else if (isString(latest_invoice.payment_intent))
    return false;
  else {
    const { payment_intent } = latest_invoice;
    return (
      payment_intent.status === 'requires_action' ||
        payment_intent.status === 'requires_payment_method'
    );
  }
  /* eslint-enable @typescript-eslint/camelcase */
}

async function createSubscriptionEffect({ paymentMethod, plan }: SubscribeState): Promise<void> {
  updateState({ subscribe: { inFlight: true } });

  const { data: subscription, error } =
    await stripeCreateSubscription({ planName: plan, paymentMethod });

  const state = getState();

  // const previous = state.account?.subscriptions ?? [];
  const previous = state && state.account && state.account.subscriptions || [];

  const subscriptions = [
    subscription,
    ...previous,
  ].filter(Boolean);

  updateState({
    account: { subscriptions, nextAction: 'getSubscriptions' },
    subscribe: { inFlight: false, subscription, error },
  });
}

async function confirmSubscriptionEffect({ subscription, stripe }: SubscribeState): Promise<void> {
  const loading = false;
  if (!isRequiresActionSubscription(subscription))
    updateState({ subscribe: { loading } });
  else {
    const { latest_invoice: { payment_intent: { client_secret: secret } } } = subscription;
    const { error } = await stripe.confirmCardPayment(secret);
    updateState({ subscribe: { loading, error } });
  }
}

const initialSearchParams = new URLSearchParams(location.search);

const initialPlan = initialSearchParams.get('plan');

function isPlan(string: string): string is Plan {
  return string === 'bronze' || string === 'silver' || string === 'gold';
}

function unsetLoading(): void {
  updateState({ subscribe: { loading: false } });
}

function onLoadStripe(): void {
  updateState({ initialized: { stripe: true } });
}

function onUnsupported(): void {
  updateState({ subscribe: { unsupported: true } });
}

function onReady({ target }: PaymentMethodEvent): void {
  const { stripe } = target;
  const unsupported = !(target instanceof StripePaymentRequest);
  updateState({ subscribe: { stripe, unsupported } });
}

function onError({ error }: ErrorEvent): void {
  updateState({ subscribe: { error } });
}

function onPaymentMethod({ target, detail: { id: paymentMethod } }: PaymentMethodEvent): void {
  updateState({ subscribe: {
    paymentMethod,
    // we set loading manually in the stripe-elements case.
    ...target instanceof StripePaymentRequest && { loading: true },
  } });
}

function planTierTemplate({ plan, price }): TemplateResult {
  const prevPlan = plan === 'bronze' ? 'gold' : plan === 'silver' ? 'bronze' : 'silver';
  const nextPlan = plan === 'bronze' ? 'silver' : plan === 'silver' ? 'gold' : 'bronze';

  return html`
    <tbt-tier>
      <h3>${plan}</h3>
      <span class="price">$${price}</span>
      <sub>Per Month</sub>

      <span class="feature" data-included="yes">
        <span>
          Audio Recordings
          <small>${plan === 'gold' ? html`From <strong>All</strong> Chaburas` : 'Four Per Month'}</small>
        </span>
      </span>

      <span class="feature" data-included="${plan === 'bronze' ? 'no' : 'yes'}">
        Live Chabura
      </span>

      <span class="feature" data-included="${plan === 'gold' ? 'yes' : 'no'}">
        <span>
          Unlimited Access
          <small>Join ${plan === 'gold' ? html`<strong>Any</strong>` : 'One'} Chabura</small>
        </span>
      </span>

      <footer>
        <nav>
          <a href="/subscribe?plan=${prevPlan}"><abbr title="previous">☜</abbr></a>
          <a href="/subscribe?plan=${nextPlan}"><abbr title="next">☞</abbr></a>
        </nav>
      </footer>
    </tbt-tier>
  `;
}

registerState({
  subscribe: {
    effects: [
      when(receivedProp('error'), unsetLoading),
      when(shouldCreateSubscription, createSubscriptionEffect),
      when(receivedProp('subscription'), confirmSubscriptionEffect),
    ],
    state: {
      inFlight: false,
      paymentMethod: null,
      unsupported: undefined,
      subscription: null,
      error: null,
      loading: false,
      plan: isPlan(initialPlan) ? initialPlan : null,
      stripe: null,
    },
  },
});

const PCI_URL = 'https://stripe-upload-api.s3.us-west-1.amazonaws.com/pci-compliance-saq321-generated-acct_172Uw8BPwxy0nKzV-1581284219.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAQOF7EIWLNL5S4XX5%2F20200209%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20200209T213701Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEE4aCXVzLXdlc3QtMiJIMEYCIQC8c7lSZnM94ZHskCAiv%2Fdh%2B%2FrRiaM%2Fcwe5ARXRolspXgIhAO3Wc9bc8GfAbL0peUR06LpZHtbyV4pdNINCe3VCWwiPKr0DCPb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMDMwNDY1NjA3MDYyIgymEe5a%2FOya8qYfHlkqkQMNA93eJ32WccEQ7xhLM78z%2BP6KJ0zVlWfZycJLYl0MQHvIGWE3xo9rrrHENRo45xWHuVfJ6R45awfiDD0lcSVuhn0cF3lm0%2BolWnC5g9%2FtpIMoKDV3%2BRsuzFgP4PwhR3d40gRHkUe4I0%2BcdsdTtL%2BET9mbtN3Fh%2BYIXETA9%2F9F%2BlwbxZNqegUFx0aL8GeuRNuHNu66%2F%2F7Ap2%2BI5xfRP7C7tRSUZy2dRIZ%2FF04hVxnFF4O%2BDqaRVNNlRiVRrw2m7R0iszX0cYBCnzOpi7yGLBvRoWd0fa93VVcfR30uMelIE0j5rWk7TQkC2Gip0rggIPZMUkWN3JnZAkhEWwmhl8LQsPpVPo3beCKcKEV1OEoCgcPe1WvvS%2FQyW2NQ%2FQlbFRDjMvOOWtmpSTMk2XD02cHrPZR2GkHAKVDhLmwy5Bf0Xqwt7b4EPnXqBiV4ntCafo9ijVDkoxn1detSBpmP4wVflb3EL%2FavWmQXhCydbjKBaolRDc0yrxziq%2B0V%2B999BaZaLYrRUdHlik29ed2YYFuxPTDV8IHyBTrqASAYTGLNR6BRC7hakEfcCBLNNrMxCVcABOjoGLyMhqu0Pm0YAUZIkL08Ms2hOfVF%2FWBm9eKM90%2BTIVEdg6NtIBGUx5kdiNWTKzTS43m9wbQA%2FPIW%2Bqr8df%2Bo3QmbURZ%2Fbqz3%2BWe2NpvaioZgl0RIieD6%2B74i3a8Hh5BNbqD%2FCFxZJ4esYOyocIe%2F%2BpNhg5n0iv2LRxrNcYGYR9jUOzKpCxqrGKQvQg8Mt%2BvWR5Afg1x1Js%2FnJNFkHGU6xI310OsBKwO4jYbTrdTnXOpSOC90QZy6NmRjKTxKwp2L2TyCHQoSYS6HYpWr3VEUwg%3D%3D&X-Amz-Signature=2c006b72cbedddcf20aaa94ff8c0040963f6c204111d3e19c096986d91b5f2f2';

const PUBLISHABLE_KEY = '__STRIPE_PUBLISHABLE_KEY__';

loadScript('https://js.stripe.com/v3').then(onLoadStripe);

@customElement('tbt-subscribe') export class TbtSubscribe extends StateElement {
  static readonly is = 'tbt-subscribe';

  static readonly styles = [
    shared,
    style,
  ];

  @query('tbt-pricing-table') pricingTable: HTMLElement;

  @query('stripe-elements') stripeElements: StripeElements;

  @query('stripe-payment-request') stripePaymentRequest: StripePaymentRequest;

  @property({ type: Boolean, reflect: true }) active = false;

  render(): TemplateResult {
    const { auth, initialized, subscribe } = this.state;

    const { user } = auth || {};
    const { stripe: stripeInitialized } = initialized;
    const { error, loading, plan, subscription, unsupported } = subscribe || {};
    const { displayName: name, email } = user || {};

    const { complete, empty, invalid } = this.stripeElements || {};

    const price = Prices[plan];

    const pk = stripeInitialized ? PUBLISHABLE_KEY : undefined;

    const resolved = !!(error || subscription);
    const disableSubmit = loading || invalid || empty || !complete;
    const hideStripeElements = loading || !!subscription || !(unsupported && stripeInitialized);

    return html`
      <nav ?hidden="${!!plan || matchMedia('(min-width: 1000px)').matches}">
        <mwc-tab-bar>
          <a href="subscribe#bronze" tabindex="-1" data-scroll-id="bronze-tier" @click="${this.scrollToTier}"><mwc-tab label="Bronze"></mwc-tab></a>
          <a href="subscribe#silver" tabindex="-1" data-scroll-id="silver-tier" @click="${this.scrollToTier}"><mwc-tab label="Silver"></mwc-tab></a>
          <a href="subscribe#gold" tabindex="-1" data-scroll-id="gold-tier" @click="${this.scrollToTier}"><mwc-tab label="Gold"></mwc-tab></a>
        </mwc-tab-bar>
      </nav>

      <tbt-pricing-table ?hidden="${!!plan}"></tbt-pricing-table>

      <article id="selected" ?hidden="${!plan}">

        <tbt-card id="tier">
          ${planTierTemplate({ plan, price })}
        </tbt-card>

        <tbt-card id="how-it-works">
          <h3 slot="heading">How it Works</h3>
          <p>
          After you register on this page, we'll contact you to confirm which chabura or chaburas you want to join.
          Once you're in our system, you'll receive a weekly email containing dial-in codes that you'll use to take part in the chaburas.
          You'll also receive a weekly link to download the audio recordings from that week's sessions.
          </p>

          <h4>Current Chaburas:</h4>

          <dl>
            <dt>Shir Hashirim</dt>
              <dd><time>Mondays 2pm Eastern</time></dd>
            <dt>Avodas Hasimcha (Likutei Eitzos)</dt>
              <dd><time>Wednesdays 12pm Eastern<time></dd>
            <dt>Daas Tvunos</dt>
              <dd><time>Thursdays 12pm Eastern</time></dd>
          </dl>
        </tbt-card>

        <tbt-auth ?hidden="${user}" ?active="${this.active}"></tbt-auth>

        <form id="payment-form" @submit="${this.onSubmit}">

          <tbt-card id="payment" ?hidden="${!user}" style="${styleMap({ pointerEvents: loading ? 'none' : 'all' })}">
            <tbt-loader ?active="${loading}">${clock}</tbt-loader>

            <h2 slot="heading">
              ${subscription ? 'Welcome to The Binah Tree'
                : error ? 'Something\'s gone wrong!'
                : 'Secure Payment'}
            </h2>

            <article>
              <stripe-payment-request id="stripe-payment-request"
                  ?hidden="${!!subscription || unsupported === true}"
                  request-payer-name
                  request-payer-email
                  request-payer-phone
                  publishable-key="${ifDefined(plan ? pk : undefined)}"
                  amount="${price * 100}"
                  label="The Binah Tree - ${capitalize(plan)}"
                  country="US"
                  currency="usd"
                  generate="payment-method"
                  @unsupported="${onUnsupported}"
                  @ready="${onReady}"
                  @payment-method="${onPaymentMethod}"
                  @error="${onError}"
              ></stripe-payment-request>

              <stripe-elements id="stripe-elements"
                  .billing-details="${{ name, email }}"
                  ?hidden="${hideStripeElements}"
                  publishable-key="${ifDefined(unsupported ? pk : undefined)}"
                  generate="payment-method"
                  @change="${this.onChange}"
                  @ready="${onReady}"
                  @payment-method="${onPaymentMethod}"
                  @error="${onError}"
              ></stripe-elements>

              <output id="payment-output">
                ${error ? html`<pre>${error && error.message}</pre>`
                  : subscription ? html`
                  <p>
                  You have successfully signed up to our Chaburah network. We will be in touch with you shortly via email to guide you into the weekly chaburah of your choice based on your membership package.

                  We will contact you shortly to help you get set up.

                  If you have any questions or issues, please email our administrator <a href="mailto:chaburas@thebinahtree.com">chaburas@thebinahtree.com</a>
                  </p>`
                  : ''}
              </output>
            </article>

            <menu id="actions" slot="actions">
              <mwc-button
                  ?hidden="${!resolved}"
                  label="${error ? 'Try Again' : 'Add Another Membership'}"
                  @click="${this.reset}"
              ></mwc-button>

              <a ?hidden="${!subscription}" href="/account" tabindex="-1">
                <mwc-button>See Your Memberships</mwc-button>
              </a>

              <mwc-button type="submit"
                  ?hidden="${!!subscription || hideStripeElements}"
                  ?disabled="${disableSubmit}"
                  @click="${this.onSubmit}"
              >Pay and Join</mwc-button>
            </menu>
          </tbt-card>

          <tbt-card id="security">
            <h4>About Security</h4>
            <p>The Binah Tree processes payments securely with <a href="https://stripe.com">Stripe</a>.
              Your payment information will be securely handled according to <a href="${PCI_URL}">PCI</a> Standards.</p>
            <p>You can <a href="https://stripe.com/docs/security/stripe">read more about Stripe's security</a> on their website.</p>
            <a slot="actions" href="https://stripe.com">
              <img alt="Powered by Stripe" src="/assets/powered_by_stripe.png" srcset="/assets/powered_by_stripe@2x.png 2x, /assets/powered_by_stripe@3x.png 3x"/>
            </a>
          </tbt-card>
        </form>

      </article>

    `;
  }

  private onChange(): void {
    this.requestUpdate();
  }

  scrollToTier({ target }: Event & { target: HTMLElement }): void {
    const { dataset: { scrollId } } = (target.tagName === 'A') ? target : target.closest('a');
    this.pricingTable.shadowRoot.getElementById(scrollId).scrollIntoView({ behavior: 'smooth' });
  }

  /** Validates the Stripe Elements Card */
  private onSubmit(event: Event): void {
    event.preventDefault();
    const { state: { subscribe } } = this;
    if (subscribe.paymentMethod && !subscribe.subscription)
      createSubscriptionEffect(this.state.subscribe);
    else if (!this.stripeElements.validate()) return;
    else {
      updateState({ subscribe: { loading: true } });
      this.stripeElements.submit();
    }
  }

  public reset(): void {
    this.stripeElements.reset();
    this.stripePaymentRequest.reset();
    updateState({ subscribe: { error: null, subscription: null, paymentMethod: null } });
  }
}
